import { useEffect } from "react";

import {
  Icon,
  IconButton,
  Button,
  useColorModeValue,
  Stack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tooltip,
  Tr,
  Th,
  Td,
  Editable,
  EditableInput,
  EditablePreview,
  useMediaQuery,
} from "@chakra-ui/react";

import { AddIcon, DeleteIcon, RepeatIcon } from "@chakra-ui/icons";

import useLocalStorage from "../Hooks/useLocalStorage";

import ContentCard from "./ContentCard";

const epsilonRound = (value) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export default function CostCard({ state, dispatch }) {
  const pln = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "PLN",
  });
  const [isMobile] = useMediaQuery("(max-width: 30em)");

  // const [expenses, setExpenses] = useState([]);
  const [expenses, setExpenses] = useLocalStorage("expenses", []);

  useEffect(() => {
    setExpenses(
      expenses.map((expense) => {
        const gross = expense.net * (1 + expense.vat / 100);
        let vatDeduction =
          (expense.net - expense.net * (1 + expense.vat / 100)) *
          -1 *
          (expense.vatDeductionRate / 100);

        let incomeTaxDeductuion = 0;
        if (state.incomes[0].formOfTaxation.split("_")[0] !== "lump") {
          incomeTaxDeductuion =
            (expense.net - expense.net * (1 + state.formOfTaxationRate / 100)) *
            -1;
        }

        return {
          ...expense,
          gross,
          taxDeduction: epsilonRound(incomeTaxDeductuion + vatDeduction),
        };
      })
    );
  }, [state]);

  const newLine = () => {
    setExpenses([
      ...expenses,
      {
        id: Date.now(),
        name: "Koszt firmowy",
        net: "0.00",
        vat: 23,
        gross: 0.0,
        vatDeduction: 0,
        vatDeductionRate: 100,
        vatExpenses: 0,
        taxDeduction: 0,
        substractFromClearIncome: true,
      },
    ]);
  };

  const removeLine = (index) => {
    const newExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(newExpenses);

    const { netExpenses, vatExpenses } = newExpenses.reduce(
      (prev, current) => {
        return {
          netExpenses: prev.netExpenses + parseFloat(current.net),
          vatExpenses: prev.vatExpenses + parseFloat(current.vatDeduction),
        };
      },
      { netExpenses: 0, vatExpenses: 0 }
    );

    dispatch({ type: "expenses", payload: { netExpenses, vatExpenses } });
  };

  const changeExpenseField = (value, field, index) => {
    let newExpenses = expenses.map((expense, i) => {
      if (i === index) {
        return { ...expense, [field]: value };
      }
      return expense;
    });

    newExpenses = newExpenses.map((expense) => {
      let net = expense.net;
      let vat = expense.vat;
      let vatDeductionRate = expense.vatDeductionRate;

      const gross = net * (1 + vat / 100);
      let vatDeduction =
        (net - net * (1 + vat / 100)) * -1 * (vatDeductionRate / 100);

      let incomeTaxDeductuion = 0;
      if (state.formOfTaxation !== "lump") {
        incomeTaxDeductuion =
          (net - net * (1 + state.formOfTaxationRate / 100)) * -1;
      }

      return {
        ...expense,
        gross,
        taxDeduction: incomeTaxDeductuion + vatDeduction,
        vatDeduction,
      };
    });

    setExpenses(newExpenses);

    const { netExpenses, vatExpenses } = newExpenses.reduce(
      (prev, current) => {
        return {
          netExpenses: prev.netExpenses + parseFloat(current.net),
          vatExpenses: prev.vatExpenses + parseFloat(current.vatDeduction),
        };
      },
      { netExpenses: 0, vatExpenses: 0 }
    );

    dispatch({ type: "expenses", payload: { netExpenses, vatExpenses } });
  };

  return (
    <ContentCard title="🛒 Koszty prowadzenia działalności">
      <Stack
        px={4}
        py={5}
        p={[null, 6]}
        bg={useColorModeValue("white", "gray.700")}
        spacing={1}
      >
        {!isMobile && (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Produkt / usługa</Th>
                <Th>Netto</Th>
                <Th>Stawka VAT</Th>
                <Th>Brutto</Th>
                <Th>Odliczenie VAT</Th>
                <Th isNumeric>Możliwe Odliczenie</Th>
                <Th>Akcje</Th>
              </Tr>
            </Thead>
            <Tbody>
              {expenses.map((expense, index) => (
                <Tr key={`${expense.id}-${index}`}>
                  <Td>
                    <Editable
                      defaultValue={expense.name}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "name", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                  <Td>
                    <Editable
                      defaultValue={expense.net}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "net", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                  <Td>
                    <Editable
                      defaultValue={expense.vat}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "vat", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                  <Td>{pln.format(expense.gross)}</Td>
                  <Td>
                    <Editable
                      defaultValue={expense.vatDeductionRate}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "vatDeductionRate", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                      {' '}%
                    </Editable>
                  </Td>
                  <Td isNumeric>{pln.format(expense.taxDeduction)}</Td>
                  <Td>
                    <IconButton
                      size={"sm"}
                      onClick={() => removeLine(index)}
                      colorScheme="red"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th></Th>
                <Th></Th>
                <Th></Th>
                <Th></Th>
                <Th isNumeric>
                  Razem:
                  {pln.format(
                    expenses.reduce(
                      (prev, current) => {
                        return {
                          taxDeduction:
                            prev.taxDeduction + current.taxDeduction,
                        };
                      },
                      { taxDeduction: 0 }
                    ).taxDeduction
                  )}
                </Th>
                <Th></Th>
              </Tr>
            </Tfoot>
          </Table>
        )}

        {isMobile && (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Opis</Th>
                <Th isNumeric>Wartość</Th>
              </Tr>
            </Thead>
            {expenses.map((expense, index) => (
              <Tbody key={index}>
                <Tr>
                  <Td>Koszt</Td>
                  <Td isNumeric>
                    <Editable
                      defaultValue={expense.name}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "name", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Netto</Td>
                  <Td isNumeric>
                    <Editable
                      defaultValue={expense.net}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "net", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Stawka VAT</Td>
                  <Td isNumeric>
                    <Editable
                      defaultValue={expense.vat}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "vat", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Brutto</Td>
                  <Td isNumeric>{pln.format(expense.gross)}</Td>
                </Tr>
                <Tr>
                  <Td>Odliczenie VAT</Td>
                  <Td isNumeric>
                    <Editable
                      defaultValue={expense.vatDeductionRate}
                      onChange={(stringValue) =>
                        changeExpenseField(stringValue, "vatDeductionRate", index)
                      }
                    >
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Możliwe odliczenie</Td>
                  <Td isNumeric>{pln.format(expense.taxDeduction)}</Td>
                </Tr>
                <Tr>
                  <Td>Usuń</Td>
                  <Td isNumeric>
                    <IconButton
                      size={"sm"}
                      onClick={() => removeLine(index)}
                      colorScheme="red"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                    />
                  </Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
        )}
        <Button
          size={"sm"}
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() => newLine()}
        >
          Dodaj koszt
        </Button>
      </Stack>
    </ContentCard>
  );
}
