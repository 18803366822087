import { useReducer } from "react";
import {
  // Alert,
  // AlertIcon,
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
  useColorModeValue,
  Text,
  Stack
} from "@chakra-ui/react";
// import { Link } from '@chakra-ui/react'
// import { ExternalLinkIcon } from '@chakra-ui/icons'

import useLocalStorage from "./Hooks/useLocalStorage";

import Nav from "./Components/Nav";
import IncomeCard from "./Components/IncomeCard";
import SocialCard from "./Components/SocialCard";
import OtherSettingsCard from "./Components/OtherSettingsCard";
import CostCard from "./Components/CostCard";
import SponsorCard from "./Components/SponsorCard";

function App() {
  //2022
  // const PROGNOSED_WAGE_IN_THE_NATIONAL_ECONOMY_2022 = 5922; //6221.04;
  // const AVG_WAGE_IN_THE_NATIONAL_ECONOMY_2022 = 6221.04;
  // const MIN_WAGE_IN_THE_NATIONAL_ECONOMY_2022 = 3010;
  //2023
  // const PROGNOSED_WAGE_IN_THE_NATIONAL_ECONOMY_2023 = 6935; //6221.04;
  // const AVG_WAGE_IN_THE_NATIONAL_ECONOMY_2023 = 6965.94;
  // const MIN_WAGE_IN_THE_NATIONAL_ECONOMY_2023 = 3490;
  //2024
  // const PROGNOSED_WAGE_IN_THE_NATIONAL_ECONOMY_2024 = 7824; //6221.04;
  // const AVG_WAGE_IN_THE_NATIONAL_ECONOMY_2024 = 7767.85;
  // const MIN_WAGE_IN_THE_NATIONAL_ECONOMY_2024 = 4242;
  //2025
  const PROGNOSED_WAGE_IN_THE_NATIONAL_ECONOMY = 8673; //6221.04;
  const AVG_WAGE_IN_THE_NATIONAL_ECONOMY =  8549.18;
  const MIN_WAGE_IN_THE_NATIONAL_ECONOMY = 4666;

  const SOCIAL = 0.6 * PROGNOSED_WAGE_IN_THE_NATIONAL_ECONOMY; //60%
  const SOCIAL_PREF = 0.3 * MIN_WAGE_IN_THE_NATIONAL_ECONOMY; //30%
  const SOCIAL_START = 0; //0%
  const RETIREMENT = 0.1952;
  const PROFITABLE = 0.08;
  const ACCIDENT = 0.0167;
  const SICKNESS = 0.0245;
  const LABOR_FUND = 0.0245;
  const MIN_HEALTH_RATE = 0.09;

  const epsilonRound = (value) =>
    Math.round((value + Number.EPSILON) * 100) / 100;

  const CONSTANTS = {
    SOCIAL: {
      RETIREMENT: epsilonRound(SOCIAL * RETIREMENT),
      PROFITABLE: epsilonRound(SOCIAL * PROFITABLE),
      ACCIDENT: epsilonRound(SOCIAL * ACCIDENT),
      SICKNESS: epsilonRound(SOCIAL * SICKNESS),
      LABOR_FUND: epsilonRound(SOCIAL * LABOR_FUND),
    },
    SOCIAL_PREF: {
      RETIREMENT: epsilonRound(SOCIAL_PREF * RETIREMENT),
      PROFITABLE: epsilonRound(SOCIAL_PREF * PROFITABLE),
      ACCIDENT: epsilonRound(SOCIAL_PREF * ACCIDENT),
      SICKNESS: epsilonRound(SOCIAL_PREF * SICKNESS),
      LABOR_FUND: epsilonRound(SOCIAL_PREF * LABOR_FUND * 0),
    },
    SOCIAL_START: {
      RETIREMENT: epsilonRound(SOCIAL_START * RETIREMENT),
      PROFITABLE: epsilonRound(SOCIAL_START * PROFITABLE),
      ACCIDENT: epsilonRound(SOCIAL_START * ACCIDENT),
      SICKNESS: epsilonRound(SOCIAL_START * SICKNESS),
      LABOR_FUND: epsilonRound(SOCIAL_START * LABOR_FUND),
    },
    MIDDLE_CLASS_RELIEF: [
      {
        THRESHOLD_FROM: Number.MIN_SAFE_INTEGER,
        THRESHOLD_TO: 68412,
        RELIEF: () => 0,
      },
      {
        THRESHOLD_FROM: 68412.01,
        THRESHOLD_TO: 102588,
        RELIEF: (A) => {
          return epsilonRound((A * 0.0668 - 4566) / 0.17);
        },
      },
      {
        THRESHOLD_FROM: 102588.01,
        THRESHOLD_TO: 133692,
        RELIEF: (A) => {
          return epsilonRound((A * -0.0735 + 9829) / 0.17);
        },
      },
      {
        THRESHOLD_FROM: 133692.01,
        THRESHOLD_TO: Number.MAX_SAFE_INTEGER,
        RELIEF: () => 0,
      },
    ],
    SCALE: [
      {
        THRESHOLD_FROM: 0,
        THRESHOLD_TO: 30000,
        TAX_REDUCE: 0,
        TAX_RATE: 0,
      },
      {
        THRESHOLD_FROM: 30000.01,
        THRESHOLD_TO: 120000,
        TAX_REDUCE: 0.12 * 30000,
        TAX_RATE: 0.12,
      },
      {
        THRESHOLD_FROM: 120000.01,
        THRESHOLD_TO: Number.MAX_SAFE_INTEGER,
        TAX_REDUCE: 20400 - 0.12 * 30000,
        TAX_RATE: 0.32,
      },
    ],
    SCALE_SPOUSE: [
      {
        THRESHOLD_FROM: 0,
        THRESHOLD_TO: 60000,
        TAX_REDUCE: 0,
        TAX_RATE: 0,
      },
      {
        THRESHOLD_FROM: 60000.01,
        THRESHOLD_TO: 240000,
        TAX_REDUCE: (0.12 * 60000) / 12,
        TAX_RATE: 0.12,
      },
      {
        THRESHOLD_FROM: 240000.01,
        THRESHOLD_TO: Number.MAX_SAFE_INTEGER,
        TAX_REDUCE: (40800 - 0.12 * 60000) / 12,
        TAX_RATE: 0.32,
      },
    ],
    MIN_HEALTH_VALUE: epsilonRound(
      MIN_HEALTH_RATE * MIN_WAGE_IN_THE_NATIONAL_ECONOMY
    ),
    HEALTH_SCALE: 0.09,
    HEALTH_FLAT: 0.049,
    HEALTH_LUMP: [
      {
        THRESHOLD: 60000,
        BASE: 0.6 * AVG_WAGE_IN_THE_NATIONAL_ECONOMY * 0.09,
        LEVEL: 1,
      },
      {
        THRESHOLD: 300000,
        BASE: 1 * AVG_WAGE_IN_THE_NATIONAL_ECONOMY * 0.09,
        LEVEL: 2,
      },
      {
        THRESHOLD: Number.MAX_SAFE_INTEGER,
        BASE: 1.8 * AVG_WAGE_IN_THE_NATIONAL_ECONOMY * 0.09,
        LEVEL: 3,
      },
    ],
  };

  const initialIncomeState = {
    monthly: 16000,
    daily: 800,
    hourly: 100,
    days: 20,
    hours: 8,
    vat: "23",
    formOfTaxation: "scale",
    currency: "PLN",
    rate: 1,
  };

  const initialState = {
    incomes: [initialIncomeState],
    clearIncome: 0,
    socialPolicy: "Brak ulgi",
    sickness: false,
    vacation: false,
    vacationDays: 21,
    ipbox: false,
    ipboxIncomePart: 0,
    middleClassRelief: false,
    married: false,
    spouseIncome: 0,
    netExpenses: 0,
    vatExpenses: 0,
    toPay: {
      vat: 0,
      incomeTax: 0,
      socialTax: {
        sum: 0,
      },
    },
  };

  const reducer = (state, action) => {
    let newState = state;
    const { index = 0, value = 0 } = action.payload;
    switch (action.type) {
      case "monthly":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              monthly: value,
              daily: value / income.days,
              hourly: value / income.days / income.hours,
            };
          }
          return income;
        });
        break;
      case "daily":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              daily: value,
              monthly: value * income.days,
              hourly: value / income.hours,
            };
          }
          return income;
        });
        break;
      case "hourly":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              hourly: value,
              daily: value * income.hours,
              monthly: value * income.hours * income.days,
            };
          }
          return income;
        });
        break;
      case "day":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              days: value,
              monthly: income.daily * value,
            };
          }
          return income;
        });
        break;
      case "hours":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              hours: value,
              daily: value * income.hourly,
              monthly: value * income.hourly * income.days,
            };
          }
          return income;
        });
        break;
      case "currency":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return {
              ...income,
              currency: value.currency,
              rate: value.rate || 1,
            };
          }
          return income;
        });
        break;
      case "vat":
        newState.incomes = state.incomes.map((income, i) => {
          if (i === index) {
            return { ...income, [action.type]: value };
          }
          return income;
        });
        break;
      case "formOfTaxation":

        // newState.middleClassRelief =
        //   value !== "scale" ? false : state.middleClassRelief;
        if (!value.includes("lump")) {
          newState.incomes = state.incomes.map((income, i) => {
            return { ...income, formOfTaxation: value };
          });
          newState.ipbox = value !== "flat" ? false : state.ipbox;
          newState.married = value !== "scale" ? false : state.married;
        } else {
          //Change scale / flat to lump
          newState.incomes = state.incomes.map((income, i) => {
            if (i === index) {
              return {
                ...income,
                formOfTaxation: value,
              };
            }

            return {
              ...income,
              formOfTaxation: !income.formOfTaxation.includes("lump")
                ? value
                : income.formOfTaxation,
            };
          });
        }
        break;
      case "newIncome":
        return calculateB2B({
          ...state,
          incomes: [
            ...state.incomes,
            {
              ...initialIncomeState,
              formOfTaxation: state.incomes[0].formOfTaxation,
            },
          ],
        });

      case "removeIncome":
        return calculateB2B({
          ...state,
          incomes: state.incomes.filter((_, i) => i !== index),
        });

      case "socialPolicy":
        newState = {
          ...state,
          socialPolicy: action.payload,
          sickness:
            action.payload === "Ulga na start" ||
              action.payload === "Umowa o pracę"
              ? false
              : state.sickness,
        };
        break;
      case "expenses":
        newState = {
          ...state,
          netExpenses: action.payload.netExpenses
            ? action.payload.netExpenses
            : 0,
          vatExpenses: action.payload.vatExpenses,
        };
        break;
      default:
        newState = {
          ...state,
        };
        newState[action.type] = action.payload;
    }
    let calculatedState = calculateB2B(newState);
    setInitialLocalStorage(calculatedState);
    return calculatedState;
  };

  const calculateSocialContribution = ({ socialPolicy, sickness }) => {
    let sum = 0;
    let taxReduction = 0;
    switch (socialPolicy) {
      case "Ulga na start":
      case "Umowa o pracę":
        return {
          sum: 0,
          taxReduction: 0,
          ...CONSTANTS.SOCIAL_START,
        };
      case "Składka preferencyjna":
        sum = Object.entries(CONSTANTS.SOCIAL_PREF).reduce(
          (sum, [key, value]) => {
            return !sickness && key === "SICKNESS" ? sum : (sum += value);
          },
          0
        );

        taxReduction = Object.entries(CONSTANTS.SOCIAL_PREF).reduce(
          (sum, [key, value]) => {
            return (!sickness && key === "SICKNESS") || key === "LABOR_FUND"
              ? sum
              : (sum += value);
          },
          0
        );

        return {
          sum,
          taxReduction,
          ...CONSTANTS.SOCIAL_PREF,
        };
      default:
        sum = Object.entries(CONSTANTS.SOCIAL).reduce((sum, [key, value]) => {
          return !sickness && key === "SICKNESS" ? sum : (sum += value);
        }, 0);

        taxReduction = Object.entries(CONSTANTS.SOCIAL).reduce(
          (sum, [key, value]) => {
            return (!sickness && key === "SICKNESS") || key === "LABOR_FUND"
              ? sum
              : (sum += value);
          },
          0
        );

        return {
          sum,
          taxReduction,
          ...CONSTANTS.SOCIAL,
        };
    }
  };

  const calculateHealthContribution = ({ formOfTaxation, calculationBase }) => {
    let healtTax = CONSTANTS.MIN_HEALTH_VALUE;
    switch (formOfTaxation) {
      case "scale":
        healtTax = calculationBase * CONSTANTS.HEALTH_SCALE;
        return healtTax > CONSTANTS.MIN_HEALTH_VALUE
          ? healtTax
          : CONSTANTS.MIN_HEALTH_VALUE;
      case "flat":
        healtTax = calculationBase * CONSTANTS.HEALTH_FLAT;
        return healtTax > CONSTANTS.MIN_HEALTH_VALUE
          ? healtTax
          : CONSTANTS.MIN_HEALTH_VALUE;
      case "lump":
        const { BASE } = CONSTANTS.HEALTH_LUMP.find(
          (level) => calculationBase * 12 <= level.THRESHOLD
        );
        return BASE;
      default:
        return 0;
    }
  };

  // const calculateMiddleClassRelief = (middleClassReliefBase) => {
  //   const { RELIEF } = CONSTANTS.MIDDLE_CLASS_RELIEF.find(
  //     (level) =>
  //       middleClassReliefBase >= level.THRESHOLD_FROM &&
  //       middleClassReliefBase <= level.THRESHOLD_TO
  //   );
  //   return RELIEF(middleClassReliefBase);
  // };

  const calculateIncomeTax = ({
    formOfTaxation,
    formOfTaxationRate,
    taxCalculationBase,
    middleClassRelief,
    healthTax,
    ipbox,
    ipboxIncomePart,
    netExpenses,
    married,
    spouseIncome,
  }) => {
    let incomeTax = 0;
    spouseIncome = married ? spouseIncome : 0;
    ipboxIncomePart = ipbox ? ipboxIncomePart : 0;

    switch (formOfTaxation) {
      case "scale":
        taxCalculationBase -= netExpenses;
        taxCalculationBase *= 12;
        taxCalculationBase += spouseIncome ? parseFloat(spouseIncome) : 0;
        taxCalculationBase -= middleClassRelief;

        let { THRESHOLD_FROM, TAX_REDUCE, TAX_RATE } = married
          ? CONSTANTS.SCALE_SPOUSE.find(
            (level) => taxCalculationBase <= level.THRESHOLD_TO
          )
          : CONSTANTS.SCALE.find(
            (level) => taxCalculationBase <= level.THRESHOLD_TO
          );

        console.log(THRESHOLD_FROM, TAX_REDUCE, TAX_RATE, taxCalculationBase);

        if (ipbox) {
          let taxCalculationBaseFull =
            taxCalculationBase * (1 - ipboxIncomePart / 100);

          let { THRESHOLD_FROM, TAX_REDUCE, TAX_RATE } = married
            ? CONSTANTS.SCALE_SPOUSE.find(
              (level) => taxCalculationBaseFull <= level.THRESHOLD_TO
            )
            : CONSTANTS.SCALE.find(
              (level) => taxCalculationBaseFull <= level.THRESHOLD_TO
            );

          incomeTax =
            TAX_RATE === 0
              ? 0
              : TAX_RATE === 0.12
                ? taxCalculationBaseFull * TAX_RATE - TAX_REDUCE
                : (taxCalculationBaseFull - THRESHOLD_FROM) * TAX_RATE +
                TAX_REDUCE;

          let taxCalculationBaseIpbox =
            taxCalculationBase * (ipboxIncomePart / 100) * 0.05;
          incomeTax += taxCalculationBaseIpbox;
        } else {
          incomeTax =
            TAX_RATE === 0
              ? 0
              : TAX_RATE === 0.12
                ? taxCalculationBase * TAX_RATE - TAX_REDUCE
                : (taxCalculationBase - THRESHOLD_FROM) * TAX_RATE + TAX_REDUCE;
        }

        incomeTax = incomeTax > 0 ? incomeTax : 0;

        return {
          incomeTax: incomeTax / 12,
          quarterIncomeTax: incomeTax / 3,
          yearIncomeTax: incomeTax,
        };
      case "flat":
        taxCalculationBase -= netExpenses;
        const healtTaxDiscount = (healthTax * 12) >= 8700 / 12 ? healthTax : 8700 / 12;
        taxCalculationBase -= healtTaxDiscount;
        if (ipbox) {
          let taxCalculationBaseFull =
            taxCalculationBase * (1 - ipboxIncomePart / 100) * 0.19;
          let taxCalculationBaseIpbox =
            taxCalculationBase * (ipboxIncomePart / 100) * 0.05;
          incomeTax = taxCalculationBaseFull + taxCalculationBaseIpbox;
        } else {
          incomeTax = taxCalculationBase * 0.19;
          incomeTax = incomeTax < 0 ? 0 : incomeTax;
        }
        return {
          incomeTax,
          quarterIncomeTax: incomeTax * 3,
          yearIncomeTax: incomeTax * 12,
        };
      default:
        taxCalculationBase -= (healthTax * 0, 5);
        incomeTax = ((taxCalculationBase * formOfTaxationRate) / 100) //+ (( healthTax * 0,5 ) / 100);
        incomeTax = incomeTax < 0 ? 0 : incomeTax;
        return incomeTax;
    }
  };

  const calculateB2B = (state) => {
    const { income, vat } = state.incomes.reduce(
      (prev, current) => {
        const currentIncome = current.monthly * current.rate;
        return {
          income: prev.income + currentIncome,
          vat: prev.vat + (currentIncome * current.vat) / 100,
        };
      },
      {
        income: 0,
        vat: 0,
      }
    );

    const formOfTaxation = state.incomes[0].formOfTaxation.split("_")[0];

    let formOfTaxationRate = 0;
    switch (formOfTaxation) {
      case "scale":
        formOfTaxationRate = 12;
        break;
      case "flat":
        formOfTaxationRate = 19;
        break;
      default:
        break;
    }

    const socialTax = calculateSocialContribution({
      socialPolicy: state.socialPolicy,
      sickness: state.sickness,
    });

    let healtTaxCalculationBase = income - socialTax.taxReduction;
    if (formOfTaxation !== "lump") healtTaxCalculationBase -= state.netExpenses;

    const healthTax = calculateHealthContribution({
      formOfTaxation,
      calculationBase: healtTaxCalculationBase,
    });

    const taxCalculationBase = income - socialTax.taxReduction;

    const middleClassRelief = 0;
    // formOfTaxation === "scale" && state.middleClassRelief
    //   ? calculateMiddleClassRelief((income - state.netExpenses) * 12)
    //   : 0;

    let incomeTax,
      quarterIncomeTax,
      yearlyIncomeTax = 0;
    // console.log('form of taxation', formOfTaxation);
    if (formOfTaxation !== "lump") {
      const income = calculateIncomeTax({
        formOfTaxation,
        formOfTaxationRate,
        taxCalculationBase,
        healthTax,
        middleClassRelief,
        ipbox: state.ipbox,
        ipboxIncomePart: state.ipboxIncomePart,
        netExpenses: state.netExpenses,
        married: state.married,
        spouseIncome: state.spouseIncome,
      });

      incomeTax = income.incomeTax;
      quarterIncomeTax = income.quarterIncomeTax;
      yearlyIncomeTax = income.yearIncomeTax;
    } else {
      incomeTax = state.incomes.reduce((prev, current) => {
        let formOfTaxationRate = parseFloat(
          current.formOfTaxation.split("_")[1]
        );

        let taxCalculationBase =
          current.monthly * current.rate -
          socialTax.taxReduction *
          ((current.monthly * current.rate * 100) / income / 100);
        if (formOfTaxation === "lump") {
          taxCalculationBase -= (healthTax * 0.5);
        }
        let incomeTax = (taxCalculationBase * formOfTaxationRate) / 100;
        incomeTax = incomeTax < 0 ? 0 : incomeTax;
        return prev + incomeTax;
      }, 0);
    }

    const vacationSavings = state.vacation
      ? (state.incomes.reduce((prev, current) => {
        let daily = current.daily * current.rate;
        return prev + daily;
      }, 0) *
        state.vacationDays) /
      12
      : 0;

    const toPayVat = state.vatExpenses > vat ? 0 : vat - state.vatExpenses;
    const vatSaving = state.vatExpenses >= vat ? vat : vat - toPayVat;

    // console.log({
    //   income,
    //   vatExpenses: state.vatExpenses,
    //   netExpenses: state.netExpenses,
    //   vatSaving,
    //   toPayVat,
    //   incomeTax,
    //   healthTax,
    //   socialTax: socialTax.sum,
    //   vacationSavings,
    // });

    const clearIncome =
      income -
      state.netExpenses -
      (state.vatExpenses - vatSaving) -
      // vatSaving -
      // toPayVat -
      incomeTax -
      healthTax -
      socialTax.sum -
      vacationSavings;

    return {
      ...state,
      income,
      formOfTaxationRate,
      vacationSavings,
      clearIncome,
      toPay: {
        vat: toPayVat,
        incomeTax,
        socialTax,
        healthTax,
        quarter: {
          incomeTax: quarterIncomeTax,
          socialTax: socialTax.sum * 3,
          healthTax: healthTax * 3,
        },
        year: {
          incomeTax: yearlyIncomeTax,
          socialTax: socialTax.sum * 12,
          healthTax: healthTax * 12,
        },
      },
    };
  };

  const [isMobile] = useMediaQuery("(max-width: 30em)");
  const [initialLocalStorage, setInitialLocalStorage] = useLocalStorage(
    "state",
    calculateB2B(initialState)
  );

  const [state, dispatch] = useReducer(
    reducer,
    calculateB2B(initialLocalStorage)
  );

  const background = useColorModeValue("white", "gray.700");

  return (
    <Box bg={useColorModeValue("gray.100", "gray.600")}>
      <Nav {...state} />

      <Box pt={[52, 44]} minH={"100vh"}>
        <SponsorCard />
        {
          /* 
        <Alert status='info'>
        <AlertIcon />
        <Link href='https://tally.so/r/wLDqxy' isExternal>
          Poszukuję osób chętnych do prowadzenia newslettera tj. pisanie: artykułów, analiz (waluty, surowce, krypto, nieruchomości), krótkich wiadomości w tematach firm i podatków w zamian za wynagrodzenie. (Ankieta) <ExternalLinkIcon mx='1px' mb='3px' />
          </Link>
        </Alert>
        */
        }
        {isMobile ? (
          <Tabs isFitted>
            <TabPanels>
              <TabPanel>
                <IncomeCard
                  title="Informacje o przychodach"
                  state={state.incomes}
                  dispatch={dispatch}
                />
                <Box mb={20} />
              </TabPanel>
              <TabPanel>
                <SocialCard state={state} dispatch={dispatch} />
                <Box mb={20} />
              </TabPanel>
              <TabPanel>
                <CostCard state={state} dispatch={dispatch} />
                <Box mb={20} />
              </TabPanel>
              <TabPanel>
                <OtherSettingsCard state={state} dispatch={dispatch} />
                <Box mb={20} />
              </TabPanel>
            </TabPanels>
            <TabList position={"fixed"} bottom={0} w="100%" bg={background}>
              <Tab>
                💰
                <br />
                Przychody
              </Tab>
              <Tab>
                👴
                <br />
                ZUS
              </Tab>
              <Tab>
                🛒
                <br />
                Koszty
              </Tab>
              <Tab>
                🚀
                <br />
                Inne
              </Tab>
            </TabList>
          </Tabs>
        ) : (
          <SimpleGrid
            columns={{ xl: 2, md: 1, sm: 1 }}
            spacing={4}
            as="main"
            mt={4}
          >
            <IncomeCard
              title="Informacje o przychodach"
              state={state.incomes}
              dispatch={dispatch}
            />
            <CostCard state={state} dispatch={dispatch} />
            <SocialCard state={state} dispatch={dispatch} />
            <OtherSettingsCard state={state} dispatch={dispatch} />
          </SimpleGrid>
        )}
      </Box>

      <Stack direction="column" alignItems="center" mt={5} pb={3}>
        <Text fontWeight="semibold">Ostatnia aktualizacja: 09.02.2025 - michal.majer[at]hotmail.com</Text>
      </Stack>
    </Box>
  );
}

export default App;
